<script>
import { defineComponent, reactive, computed, watchEffect } from "vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useBouncer } from "@/compositions/useBouncer";

export default defineComponent({
  name: "TeamMembers",
  setup() {
    const bouncer = useBouncer();
    const route = useRoute();
    const store = useStore();
    
    const state = reactive({
      filterQuery: "",
      teamId: route.params.id,
      members: [],
      team: computed(() => store.state.team)
    });

    watchEffect(() => {
      store.dispatch("team/getMembers", {id: state.teamId, filterQuery: state.filterQuery}).then(r => {
        state.members = r.data;
      });
    });

    return {
      filterQuery: computed({
        get: () => state.filterQuery,
        set: value => (state.filterQuery = value)
      }),
      members: computed(() => state.members),
      teamId: state.teamId,
      bouncer
    };
  }
});
</script>

<template>
  <section
    class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4"
  >
    <div class="relative">
      <svg
        width="20"
        height="20"
        fill="currentColor"
        class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        />
      </svg>
      <input
        v-model.lazy="filterQuery"
        class="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10"
        type="text"
        aria-label="Filter members"
        placeholder="Filter members"
      >
    </div>
    <ul
      class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4"
    >
      <li
        v-if="bouncer.can('members-invite')"
        class="hover:shadow-lg flex rounded-lg"
      >
        <router-link
          :to="{
            name: 'dashboard.team.member.invite',
            params: { id: teamId }
          }"
          class="hover:border-transparent hover:shadow-xs w-full flex items-center justify-center rounded-lg border-2 border-dashed border-gray-200 text-sm font-medium py-4 cursor-pointer"
        >
          Invite member
        </router-link>
      </li>
      <li
        v-for="member in members"
        :key="member.id"
      >
        <div
          class="group rounded-lg p-3 border border-gray-200 hover:bg-light-blue-500 hover:border-transparent hover:shadow-lg"
        >
          <dl class="flex justify-start items-center">
            <div>
              <dd
                class="flex justify-start sm:justify-start lg:justify-end xl:justify-start -space-x-2"
              >
                <img
                  :src="member.profile_photo"
                  alt="employee.name"
                  title="employee.name"
                  width="48"
                  height="48"
                  class="w-16 h-16 rounded-full bg-gray-100 border-2 border-white"
                  loading="lazy"
                >
              </dd>
            </div>
            <div class="ml-4">
              <dt class="sr-only">
                Name
              </dt>
              <dd
                class="eading-6 font-medium tex-md text-black group-hover:text-white"
              >
                {{ member.name }}
              </dd>
              <dt class="sr-only">
                position
              </dt>
              <dd
                class="text-md font-medium text-indigo-600 group-hover:text-light-blue-200 sm:mb-4 lg:mb-0 xl:mb-4"
              >
                {{ member.position.name }}
              </dd>
            </div>
          </dl>
        </div>
      </li>
    </ul>
  </section>
</template>
